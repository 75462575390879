<template>
	<TnLink
		:to="url || 'javascript:'"
		:target="target"
		:class="classList"
		:arrowRight="chevronRight"
		:arrowLeft="chevronLeft"
		:dark="dark"
		:size="size"
		class="link-inline"
	>
		<EditLinkButton
			v-if="$config.public.previewMode"
			:to="to"
			:href="href"
			:openInNewWindow="openInNewWindow"
		/>
		<span
			v-editable-string
			v-if="editable"
		>
			<slot>
				{{ firstWords }} <span class="last">{{ lastWord }}</span>
			</slot>
		</span>
		<span v-else>
			<slot>
				{{ firstWords }} <span class="last">{{ lastWord }}</span>
			</slot>
		</span>
	</TnLink>
</template>

<script>
import LinkMixin from "./LinkMixin.js";
import SupportsDarkMode from "../../../../mixins/platform/SupportsDarkMode.js";

export default defineNuxtComponent({
	name: "LinkInline",

	props: {
		text: {
			type: String,
		},
		href: {
			type: String,
			default: null,
		},
		to: {
			type: String,
		},
		openInNewWindow: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: null,
		},
		chevronRight: {
			type: Boolean,
			default: false,
		},
		chevronLeft: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: null,
			validator: (value) => {
				return ["s", "m", "l"].includes(value);
			},
		},
	},

	mixins: [LinkMixin, SupportsDarkMode],

	computed: {
		classList() {
			const classList = [];
			const dark = this.dark && "dark";
			classList.push(this.color, this.size, dark);
			return classList.length ? classList : [];
		},
		parts() {
			if (this.text && typeof this.text === "string") return this.text.trim().split(" ");
			return undefined;
		},
		lastWord() {
			if (this.parts) {
				if (this.parts.length > 1) return this.parts[this.parts.length - 1];
				return this.parts[0];
			}
			return undefined;
		},
		firstWords() {
			if (this.parts) return this.parts.slice(0, this.parts.length - 1).join(" ");
			return undefined;
		},
	},
});
</script>

<style lang="scss">
.link-inline {
	position: relative;
}
</style>
